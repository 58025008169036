import React, { ChangeEventHandler } from "react";
import { isPropertyAccessOrQualifiedName } from "typescript";
import Button from "./Button";

interface CarInsuranceFormProps {
	shown: boolean;
	hideForm: Function;
}

interface CarInsuranceFormState{
	subbmited: boolean;
	currentPage: number;

	firstname: string;	
	firstnameError: boolean;

	lastname: string;
	lastnameError: boolean;

	pesel: string;
	peselError: boolean;

	address: string;
	addressError: boolean;

	city: string;
	cityError: boolean;

	postcode: string;
	postcodeError: boolean;

	phone: string;
	phoneError: boolean;

	email: string;
	emailError: boolean;

	companyName: string;
	companyNameError: boolean;

	regon: string;
	regonError: boolean;



	brand: string;
	brandError: boolean;

	model: string;
	modelError: boolean;

	vehicleType: string;
	vehicleTypeError: boolean;

	productionYear: string;
	productionYearError: boolean;

	registrationNumber: string;

	vinNumber: string;

	leasing: boolean;

	insuranceRange: Array<MultipartFormSelectOptionValue>;
	insuranceRangeError: boolean;
	
	policyNumber: string;

	rodo: boolean;



	sendStatus: "idle" | "sending" | "success" | "failed";
	errorMessage: string;
}

class CarInsuranceForm extends React.Component<CarInsuranceFormProps, CarInsuranceFormState>{
	pages: any;

	constructor(props: any) {
		super(props);

		this.state = {
			subbmited: false,
			currentPage: 0,

			firstname: "",
			firstnameError: false,

			lastname: "",
			lastnameError: false,

			pesel: "",
			peselError: false,

			address: "",
			addressError: false,

			city: "",
			cityError: false,

			postcode: "",
			postcodeError: false,

			phone: "",
			phoneError: false,

			email: "",
			emailError: false,

			companyName: "",
			companyNameError: false,

			regon: "",
			regonError: false,



			brand: "",
			brandError: false,

			model: "",
			modelError: false,

			vehicleType: "",
			vehicleTypeError: false,

			productionYear: "",
			productionYearError: false,

			registrationNumber: "",

			vinNumber: "",

			leasing: false,

			insuranceRange: [
					{
						value: false,
						title: "OC"
					},

					{
						value: false,
						title: "AC"
					},

					{
						value: false,
						title: "NNW"
					},

					{
						value: false,
						title: "Assistance"
					},

					{
						value: false,
						title: "Szyby"
					},
			],

			insuranceRangeError: false,

			policyNumber: "",

			rodo: false,

			sendStatus: "idle",
			errorMessage: "",
		}



		// Bind this to events
		this.handleSendMessage = this.handleSendMessage.bind(this);

		this.handleInputChange = this.handleInputChange.bind(this);

		this.handleLeasingChange = this.handleLeasingChange.bind(this);
		this.handleInsuranceRangeChange = this.handleInsuranceRangeChange.bind(this);
		this.handleRodoChange = this.handleRodoChange.bind(this);

		this.handleNextPage = this.handleNextPage.bind(this);
		this.handlePreviousPage = this.handlePreviousPage.bind(this);
		this.handleCrossClick = this.handleCrossClick.bind(this);
		this.handleReturnClick = this.handleReturnClick.bind(this);

		this.getInsuranceRangeValue = this.getInsuranceRangeValue.bind(this);

		this.checkError = this.checkError.bind(this);
	}

	checkError() {
		let state = {} as any;

		let page = -1;

		let error = false;

		if(this.state.firstname === "") {
			state.firstnameError = true;
			error = true;

			if(page === -1) {
				page = 0;
			}
		} else {
			state.firstnameError = false;
		}

		// if(this.state.lastname === "") {
		// 	state.lastnameError = true;
		// 	error = true;

		// 	if(page === -1) {
		// 		page = 0;
		// 	}
		// } else {
		// 	state.lastnameError = false;
		// }

		if(this.state.pesel === "") {
			state.peselError = true;
			error = true;

			if(page === -1) {
				page = 0;
			}
		} else {
			state.peselError = false;
		}

		if(this.state.address === "") {
			state.addressError = true;
			error = true;

			if(page === -1) {
				page = 0;
			}
		} else {
			state.addressError = false;
		}

		if(this.state.city === "") {
			state.cityError = true;
			error = true;

			if(page === -1) {
				page = 0;
			}
		} else {
			state.cityError = false;
		}

		if(this.state.postcode === "") {
			state.postcodeError = true;
			error = true;

			if(page === -1) {
				page = 0;
			}
		} else {
			state.postcodeError = false;
		}

		if(this.state.phone === "") {
			state.phoneError = true;
			error = true;

			if(page === -1) {
				page = 0;
			}
		} else {
			state.phoneError = false;
		}

		if(this.state.email === "") {
			state.emailError = true;
			error = true;

			if(page === -1) {
				page = 0;
			}
		} else {
			state.emailError = false;
		}
		
		if(this.state.brand === "") {
			state.brandError = true;
			error = true;

			if(page === -1) {
				page = 1;
			}
		} else {
			state.brandError = false;
		}

		if(this.state.model === "") {
			state.modelError = true;
			error = true;

			if(page === -1) {
				page = 1;
			}
		} else {
			state.modelError = false;
		}

		if(this.state.vehicleType === "") {
			state.vehicleTypeError = true;
			error = true;

			if(page === -1) {
				page = 1;
			}
		} else {
			state.vehicleTypeError = false;
		}

		if(this.state.productionYear === "") {
			state.productionYearError = true;
			error = true;

			if(page === -1) {
				page = 1;
			}
		} else {
			state.productionYearError = false;
		}

		// Insurance range
		let insuranceRangeError = true;

		for(var i = 0; i < this.state.insuranceRange.length; i++) {
			if(this.state.insuranceRange[i].value === true) {
				insuranceRangeError = false;
				break;
			}
		}

		if(insuranceRangeError === true) {
			error = true;
			if(page === -1) {
				page = 1;
			}
		}

		if(this.state.rodo === false) {
			error = true;
			if(page === -1) {
				page = 1;
			}
		}


		state.insuranceRangeError = insuranceRangeError;


		state.currentPage = page;

		this.setState(state);

		return error;
	}

	handleSendMessage(event?: any) {
        event.preventDefault();

		this.setState({
			subbmited: true,
		});

		// Check for errors
		if(this.checkError() === true) {return}

		this.setState({
			currentPage: 2,
			sendStatus: "sending",
		});

        let xhr = new XMLHttpRequest();
        xhr.open("POST", "/sendCarInsurance.php", true);

        let data = new FormData();
        data.append("firstname", this.state.firstname);
        data.append("lastname", this.state.lastname);
        data.append("pesel", this.state.pesel);
        data.append("address", this.state.address);
        data.append("city", this.state.city);
        data.append("postcode", this.state.postcode);
        data.append("phone", this.state.phone);
        data.append("email", this.state.email);

        data.append("brand", this.state.brand);
        data.append("model", this.state.model);
        data.append("vehicleType", this.state.vehicleType);
        data.append("productionYear", this.state.productionYear);
        data.append("registrationNumber", this.state.registrationNumber);
        data.append("vinNumber", this.state.vinNumber);
        data.append("leasing", "" + this.state.leasing);
        data.append("insuranceRange", this.getInsuranceRangeValue());
        data.append("policyNumber", this.state.policyNumber);
        data.append("rodo", "" + this.state.rodo);

        xhr.onreadystatechange = (ev) => {
            if(xhr.readyState == 4) {
                if(xhr.status == 200) {
                    let response;

                    try{
                        response = JSON.parse(xhr.response);
                    } catch(e) {
                        response = {} as any;
                        response.status = "failed";
                    }

                    if(response.status === "success") {
                        this.setState({
                            sendStatus: "success",
                            errorMessage: "",
                        });
                    } else {
                        this.setState({
                            sendStatus: "failed",
                            errorMessage: "Wiadomość nie została wysłana, błąd po stronie serwera",
                        });
                    }

                } else {
                    this.setState({
                        sendStatus: "failed",
                        errorMessage: "Wiadomość nie została wysłana, błąd po stronie serwera",
                    });
                }
            }
        }

        xhr.send(data);
	}

	handleCrossClick(event: any) {
		if(this.state.currentPage == 2) {
			this.handleReturnClick();
			return;
		}
		this.props.hideForm();
	}

	handleReturnClick(event?: any) {
		this.setState({
			currentPage: 0,

			firstname: "",
			lastname: "",
			pesel: "",
			address: "",
			city: "",
			postcode: "",
			phone: "",
			email: "",

			brand: "",
			model: "",
			vehicleType: "",
			productionYear: "",
			registrationNumber: "",
			vinNumber: "",
			leasing: false,
			insuranceRange: [
					{
						value: false,
						title: "OC"
					},

					{
						value: false,
						title: "AC"
					},

					{
						value: false,
						title: "NNW"
					},

					{
						value: false,
						title: "Assistance"
					},

					{
						value: false,
						title: "Szyby"
					},
			],
			policyNumber: "",

			rodo: false,

			sendStatus: "idle",
			errorMessage: "",
		});

		this.props.hideForm();
	}

	handleInputChange(event: any) {
		this.setState({
			[event.target.name]: event.target.value
		} as Pick<CarInsuranceFormState, keyof CarInsuranceFormState>);
	}

	handleLeasingChange(event: any) {
		this.setState({leasing: !this.state.leasing});
	}

	handleInsuranceRangeChange(option: number, event: any) {
		let value = this.state.insuranceRange.slice();
		value[option].value = !value[option].value;

		this.setState({
			insuranceRange: value,
		});
	}

	handleRodoChange(event: any) {
		this.setState({rodo: !this.state.rodo});
	}


	handleNextPage() {
		if(this.state.currentPage < this.pages.length - 1) {
			this.setState({
				currentPage: this.state.currentPage + 1,
			});
		}
	}

	handlePreviousPage() {
		if(this.state.currentPage > 0) {
			this.setState({
				currentPage: this.state.currentPage - 1
			});
		}
	}

	getInsuranceRangeValue() {
		let insuranceRange = this.state.insuranceRange.slice();
		let value = [];

		for(var i = 0; i < insuranceRange.length; i++) {
			if(insuranceRange[i].value === true) {
				value.push(insuranceRange[i].title);
			}
		}

		return value.join(", ");
	}



	render() {
		let page1 = (
			<MultipartFormPage id="insurance-form-personal-data">
				<MultipartFormInput 
					id="car-insurance-firstname"
					className={`
						multipart-form__input
						${
							this.state.firstnameError ? "multipart-form__input--error" : ""
						}
					`}

					type="text"
					name="firstname"

					title="Imię i nazwisko / Nazwa firmy" 

					value={this.state.firstname}

					onChange={this.handleInputChange}
				/>

				{/* <MultipartFormInput
					id="car-insurance-lastname"
					className={`
						multipart-form__input
						${
							this.state.lastnameError ? "multipart-form__input--error" : ""
						}
					`}

					type="text"
					name="lastname"

					title="Nazwisko" 

					value={this.state.lastname}
					onChange={this.handleInputChange}
				/> */}

				<MultipartFormInput
					id="car-insurance-pesel"
					className={`
						multipart-form__input
						${
							this.state.peselError ? "multipart-form__input--error" : ""
						}
					`}


					type="text"
					name="pesel"

					title="Pesel / REGON" 

					value={this.state.pesel}
					onChange={this.handleInputChange}
				/>

				<MultipartFormInput
					id="car-insurance-address"
					className={`
						multipart-form__input
						${
							this.state.addressError ? "multipart-form__input--error" : ""
						}
					`}


					type="text"
					name="address"

					title="Adres" 

					value={this.state.address}
					onChange={this.handleInputChange}
				/>

				<MultipartFormInput
					id="car-insurance-city"
					className={`
						multipart-form__input
						${
							this.state.cityError ? "multipart-form__input--error" : ""
						}
					`}


					type="text"
					name="city"

					title="Miasto" 

					value={this.state.city}
					onChange={this.handleInputChange}
				/>

				<MultipartFormInput
					id="car-insurance-postcode"
					className={`
						multipart-form__input
						${
							this.state.postcodeError ? "multipart-form__input--error" : ""
						}
					`}


					type="text"
					name="postcode"

					title="Kod pocztowy" 

					value={this.state.postcode}
					onChange={this.handleInputChange}
				/>

				<MultipartFormInput
					id="car-insurance-phone"
					className={`
						multipart-form__input
						${
							this.state.phoneError ? "multipart-form__input--error" : ""
						}
					`}


					type="text"
					name="phone"

					title="Numer telefonu" 

					value={this.state.phone}
					onChange={this.handleInputChange}
				/>

				<MultipartFormInput
					id="car-insurance-email"
					className={`
						multipart-form__input
						${
							this.state.emailError ? "multipart-form__input--error" : ""
						}
					`}

					type="text"
					name="email"

					title="E-mail" 

					value={this.state.email}
					onChange={this.handleInputChange}
				/>

				<MultipartFormCheckbox 
					id="car-insurance-leasing"
					className="multipart-form-checkbox"

					name="leasing"

					text="Czy pojazd jest w leasingu?" 

					checked={this.state.leasing}

					onChange={this.handleLeasingChange}
				/>
			</MultipartFormPage>
		);

		let page2 = (
			<MultipartFormPage id="insurance-form-car-data">
				<MultipartFormInput 
					id="car-insurance-brand"
					className={`
						multipart-form__input
						${
							this.state.brandError ? "multipart-form__input--error" : ""
						}
					`}


					type="text"
					name="brand"

					title="Marka" 

					value={this.state.brand}

					onChange={this.handleInputChange}
				/>

				<MultipartFormInput 
					id="car-insurance-model"
					className={`
						multipart-form__input
						${
							this.state.modelError ? "multipart-form__input--error" : ""
						}
					`}


					type="text"
					name="model"

					title="Model" 

					value={this.state.model}

					onChange={this.handleInputChange}
				/>

				{/* <MultipartFormInput 
					id="car-insurance-vehicle-type"
					className={`
						multipart-form__input
						${
							this.state.vehicleTypeError ? "multipart-form__input--error" : ""
						}
					`}


					type="text"
					name="vehicleType"

					title="Rodzaj pojazdu" 

					value={this.state.vehicleType}

					onChange={this.handleInputChange}
				/> */}

				<select
					id="car-insurance-vehicle-type"
					className={`
						multipart-form__input
						multipart-form__select
						${
							this.state.vehicleTypeError ? "multipart-form__input--error" : ""
						}
					`}

					name="vehicleType"

					placeholder="Rodzaj pojazdu" 

					value={this.state.vehicleType}

					onChange={this.handleInputChange}
				>
					<option>Rodzaj pojazdu</option>
					<option>Auta małe</option>
					<option>Auta miejskie</option>
					<option>Coupe</option>
					<option>Kabriolet</option>
					<option>Kombi</option>
					<option>Kompakt</option>
					<option>Minivan</option>
					<option>Sedan</option>
					<option>SUV</option>
				</select>

				<MultipartFormInput 
					id="car-insurance-production-year"
					className={`
						multipart-form__input
						${
							this.state.productionYearError ? "multipart-form__input--error" : ""
						}
					`}


					type="text"
					name="productionYear"

					title="Rok produkcji" 

					value={this.state.productionYear}

					onChange={this.handleInputChange}
				/>

				<MultipartFormInput 
					id="car-insurance-registration-number"
					className="multipart-form__input"

					type="text"
					name="registrationNumber"

					title="Numer rejestracyjny" 

					value={this.state.registrationNumber}

					onChange={this.handleInputChange}
				/>

				<MultipartFormInput 
					id="car-insurance-vin-number"
					className="multipart-form__input"

					type="text"
					name="vinNumber"

					title="Numer VIN" 

					value={this.state.vinNumber}

					onChange={this.handleInputChange}
				/>


				<MultipartFormSelect
					id="car-insurance-range"
					className={`
						${
							this.state.insuranceRangeError ? "multipart-form-select--error" : ""
						}
					`}


					name="insurance-range"

					options={["OC", "AC", "NNW", "Assistance", "Szyby"]}

					onChange={this.handleInsuranceRangeChange}

					value={this.state.insuranceRange}
				/>

				<MultipartFormInput 
					id="car-insurance-policy-number"
					className="multipart-form__input"

					type="text"
					name="policyNumber"

					title="Numer aktualnej polisy" 

					value={this.state.policyNumber}

					onChange={this.handleInputChange}
				/>

			</MultipartFormPage>
		);

		let sendStatus;

		switch(this.state.sendStatus) {
			case "sending":
				sendStatus = (<MultipartFormStatusSending/>);
			break;
			
			case "success":
				sendStatus = (<MultipartFormStatusSuccess/>);
			break;

			case "failed":
				sendStatus = (<MultipartFormStatusFailed/>);
			break;
		}

		let page3 = sendStatus;
		
		this.pages = [page1, page2, page3];

		let nav1 = (
			<div className="multipart-form__navigation">
				<MultipartFormCheckbox 
					id="car-insurance-rodo"

					className={`
						multipart-form-checkbox

						${ this.state.subbmited ? !this.state.rodo ? "multipart-form-checkbox--error" : "" : "" }
					`}

					name="rodo"

					text="
					Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem poinformowany że przysługuje mi prawo dostępu do swoich dancyh, możliwości ich poprawiania, żadania zaprzestania ich przetwarzania. Administratorem danych osobowych jest nazwa firmy z pełnym adresem
					" 

					checked={this.state.rodo}

					onChange={this.handleRodoChange}
				/>

				<div className="multipart-form__navigation-buttons">
					<Button onClick={this.handleNextPage} textColor="black" backgroundColor="yellow"> Dalej </Button>
				</div>
			</div>
		);

		let nav2 = (
			<div className="multipart-form__navigation">
				<MultipartFormCheckbox 
					id="car-insurance-rodo"
					className={`
						multipart-form-checkbox

						${ this.state.subbmited ? !this.state.rodo ? "multipart-form-checkbox--error" : "" : "" }
					`}

					name="rodo"

					text="
					Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem poinformowany że przysługuje mi prawo dostępu do swoich dancyh, możliwości ich poprawiania, żadania zaprzestania ich przetwarzania. Administratorem danych osobowych jest nazwa firmy z pełnym adresem
					" 

					checked={this.state.rodo}

					onChange={this.handleRodoChange}
				/>

				<div className="multipart-form__navigation-buttons">
						<Button onClick={this.handlePreviousPage} style="outline" textColor="white" backgroundColor="white"> Wstecz </Button>	
						<Button onClick={this.handleSendMessage} textColor="black" backgroundColor="yellow"> Wyślij </Button>
				</div>
			</div>
		);

		let nav3 = (
			<div className="multipart-form__navigation">
				<div></div>
				<div className="multipart-form__navigation-buttons">
					<Button onClick={this.handleReturnClick} textColor="black" backgroundColor="yellow"> Powrót do strony głównej </Button>
				</div>
			</div>
		);

		let navs = [nav1, nav2, nav3];


		return(
			<div 
				id="car-insurance-form" 
				className={`
					multipart-form
					${this.props.shown ? "multipart-form--shown" : ""}
				`}
			>
				<div className="multipart-form__header">
					<div className="multipart-form__header-title">
						Wypełnij formularz, a my obliczymy dla ciebie cene ubezpieczenia
					</div>

					<div onClick={this.handleCrossClick} className="multipart-form__cross">
						<div className="multipart-form__cross-line"></div>
						<div className="multipart-form__cross-line"></div>
					</div>
				</div>

				<div className="multipart-form__main-container">
					<div className="multipart-form__pages-container">
						{this.pages[this.state.currentPage]}
					</div>

					<MultipartFormPageStatus
						pages={["Dane osobowe", "Dane samochodu", "Koniec"]}
						current={this.state.currentPage}
					/>
				</div>

				{navs[this.state.currentPage]}
			</div>
		);
	}
}



class MultipartFormPage extends React.Component<any>{
	constructor(props: any) {
		super(props);
	}

	render() {
		return(
			<div id={this.props.id} className="multipart-form__page">
				{this.props.children}
			</div>
		);
	}
}

function MultipartFormPageStatus(props: any) {
	let pages = props.pages.map((page: string, i: number) => {	
		return (
			<MultipartFormPageStatusElement
				number={i+1}
				title={page}
				current={props.current ===  i ? true : false}
				key={page}
			/>
		);
	});

	return(
		<div className="multipart-form-status">
			{pages}
		</div>
	);
}

function MultipartFormPageStatusElement(props: any) {
	return (
		<div className=
		{`
			multipart-form-status__element
			${props.current ? "multipart-form-status--current" : ""}
		`}>
			<div className="multipart-form-status__number">{props.number}</div>
			<div className="multipart-form-status__title">{props.title}</div>
		</div>
	);
}


// Input
interface MultipartFormInputProps{
	id: string;
	className: string;

	type: string;
	name: string;
	value: string;

	title: string;
	onChange?: ChangeEventHandler;
}

class MultipartFormInput extends React.Component<MultipartFormInputProps>{
	constructor(props: any) {
		super(props);
	}

	render() {
		return(
			<input 
				placeholder={this.props.title}
				type={this.props.type} 
				name={this.props.name} 
				id={this.props.id} 
				className={this.props.className}
				value={this.props.value}
				onChange={this.props.onChange}
			/>
		);
	}
}



// Checkbox
interface MultipartFormCheckboxProps{
	id: string;
	className: string;

	name: string;
	checked: boolean;

	text: string;

	onChange?: ChangeEventHandler;
}

class MultipartFormCheckbox extends React.Component<MultipartFormCheckboxProps> {
	constructor(props: any) {
		super(props);
	}

	render() {
		return(
			<label className={this.props.className}>
				<input
					type="checkbox"
					name={this.props.name} 
					id={this.props.id} 
					className={`multipart-form-checkbox__input`}
					defaultChecked={this.props.checked}
					onChange={this.props.onChange}
				/>

				<div id={`${this.props.id}__text`} className={`multipart-form-checkbox__text`}>
					{this.props.text}
				</div>
			</label>
		);
	}
}



// Select with checkboxes
interface MultipartFormSelectProps {
	id: string;
	className: string;
	name: string;
	options: Array<string>;
	onChange?: any;
	value: Array<MultipartFormSelectOptionValue>;
}

interface MultipartFormSelectState{
	optionsHidden: boolean;
	value: Array<boolean>;
}

interface MultipartFormSelectOptionValue {
	value: boolean;
	title: string;
}

class MultipartFormSelect extends React.Component<MultipartFormSelectProps, MultipartFormSelectState>{
	optionComponents: any;

	constructor(props: any) {
		super(props);

		this.state = {
			optionsHidden: true,
			value: this.props.options.map(() => {
				return false;
			})
		}



		this.showOptions = this.showOptions.bind(this);
		this.hideOptions = this.hideOptions.bind(this);
		this.toggleOptions = this.toggleOptions.bind(this);
		this.handleCurrentOptionClick = this.handleCurrentOptionClick.bind(this);
		this.getValue = this.getValue.bind(this);
	}

	showOptions() {
		this.setState({optionsHidden: false});
	}

	hideOptions() {
		this.setState({optionsHidden: true});
	}

	toggleOptions() {
		this.setState({optionsHidden: !this.state.optionsHidden});
	}

	handleCurrentOptionClick(event: any) {
		this.toggleOptions();
	}

	getValue() {
		let value = [];
		
		for(var i = 0; i < this.state.value.length; i++) {
			let option: MultipartFormSelectOptionValue  = {
				value : this.state.value[i],
				title: this.props.options[i],
			};

			value.push(option);
		}

		return value;
	}

	render() {
		let optionComponents = [];
		let currentOption = [];

		for(var i = 0; i < this.props.value.length; i++) {
			let value = this.props.value[i].value;
			let title = this.props.value[i].title;
			let optionId = i;

			if(value == true) {
				currentOption.push(title);
			}

			let component = (
				<MultipartFormSelectOption
					onChange={this.props.onChange}

					value={value}
					optionId={optionId}

					key={title}
				>{title}</MultipartFormSelectOption>
			);

			optionComponents.push(component);
		}

		let currentOptionText;

		if(currentOption.length === 0) {
			currentOptionText = "Zakres ubezpieczenia";
		} else {
			currentOptionText = currentOption.join(", ");
		}

		return(
			<div className={
				`
					multipart-form-select
					${this.state.optionsHidden ? "" : "multipart-form-select--shown"}	

					${this.props.className}
				`
			}>
				<div
					onClick={this.handleCurrentOptionClick}
					className="multipart-form-select__current-option"
				>
					{currentOptionText}
					<div className="multipart-form-select__triangle-container">
						<svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m2.095 19.882 9.248-16.5c.133-.237.384-.384.657-.384.272 0 .524.147.656.384l9.248 16.5c.064.115.096.241.096.367 0 .385-.309.749-.752.749h-18.496c-.44 0-.752-.36-.752-.749 0-.126.031-.252.095-.367z" fillRule="nonzero"/></svg>	
					</div>
				</div>

				<div className="multipart-form-select__options-container">
					{optionComponents}
				</div>
			</div>
		)
	}
}

interface MultipartFormSelectOptionProps {
	children?: | React.ReactChild | React.ReactChild[];
	onChange: Function;
	optionId: number;
	value: boolean;
}

class MultipartFormSelectOption extends React.Component<MultipartFormSelectOptionProps>{
	constructor(props: any) {
		super(props);
	}

	render() {
		return(
			<label className="multipart-form-select__option">
				<input
					type="checkbox" 
					className="multipart-form-select__option-checkbox"
					onChange={this.props.onChange.bind(this, this.props.optionId)}
					checked={this.props.value ? true : false}
				/> 
				{this.props.children}
			</label>
		);
	}
}

function MultipartFormStatusSending(props: any) {
	return(
		<div className="multipart-form-sendStatus">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 0l-4.5 16.5-6.097-5.43 5.852-6.175-7.844 5.421-5.411-1.316 18-9zm-11 12.501v5.499l2.193-3.323-2.193-2.176zm-8.698 6.825l-1.439-.507 5.701-5.215 1.436.396-5.698 5.326zm3.262 4.287l-1.323-.565 4.439-4.503 1.32.455-4.436 4.613zm-4.083.387l-1.481-.507 8-7.89 1.437.397-7.956 8z"/></svg>

			<div className="multipart-form-sendStatus__title"> Wysyłanie </div>
		</div>
	);
}

function MultipartFormStatusSuccess(props: any) {
	return(
		<div className="multipart-form-sendStatus">
			<svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-5.049 10.386 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z" fillRule="nonzero"/></svg>

			<div className="multipart-form-sendStatus__title"> Wiadomość została wysłania </div>

			<div className="multipart-form-sendStatus__description"> Wkrótce się do ciebie odezwiemy </div>
		</div>
	);
}

function MultipartFormStatusFailed(props: any) {
	return(
		<div className="multipart-form-sendStatus">
			<svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 8.933-2.721-2.722c-.146-.146-.339-.219-.531-.219-.404 0-.75.324-.75.749 0 .193.073.384.219.531l2.722 2.722-2.728 2.728c-.147.147-.22.34-.22.531 0 .427.35.75.751.75.192 0 .384-.073.53-.219l2.728-2.728 2.729 2.728c.146.146.338.219.53.219.401 0 .75-.323.75-.75 0-.191-.073-.384-.22-.531l-2.727-2.728 2.717-2.717c.146-.147.219-.338.219-.531 0-.425-.346-.75-.75-.75-.192 0-.385.073-.531.22z" fillRule="nonzero"/></svg>

			<div className="multipart-form-sendStatus__title"> Wiadomość nie została wysłana </div>
			<div className="multipart-form-sendStatus__description"> Błąd po stronie serwera </div>
		</div>
	);
}


export default CarInsuranceForm;

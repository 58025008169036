import React from "react";
import Button from "./Button";

function Contact(props: any) {
    return(
        <div id="kontakt" className="contact">
            <div className="section-title">Kontakt</div>

            <div className="contact-container">
                <ContactInfo/>            
                <ContactForm/>
            </div>
        </div>
    );
}

function ContactInfo(props: any) {
    return(
        <div className="contact-info-container">
            <ContactInfoElement
                svg={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.48 22.926l-1.193.658c-6.979 3.621-19.082-17.494-12.279-21.484l1.145-.637 3.714 6.467-1.139.632c-2.067 1.245 2.76 9.707 4.879 8.545l1.162-.642 3.711 6.461zm-9.808-22.926l-1.68.975 3.714 6.466 1.681-.975-3.715-6.466zm8.613 14.997l-1.68.975 3.714 6.467 1.681-.975-3.715-6.467z"/></svg>}
                href="callto:+48512995816"
                text="+48 512 995 816"
            />

            <ContactInfoElement
                svg={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/></svg>}
                href="mailto:biuro@czasprosperity.pl"
                text="biuro@czasprosperity.pl"
            />

            <ContactInfoElement
                svg={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>}
                text="ul. Gabrieli Zapolskiej 7,  40-580 Katowice"
            />
        </div>
    );
}

function ContactInfoElement(props: any) {
    return(
        <a href={props.href} className="contact-info-element">
            {props.svg}

            {props.text}
        </a>
    );
}

interface ContactFormState{
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    message: string;
    rodo: boolean;

    errorMessage: string;
    sendStatus: boolean;
}

class ContactForm extends React.Component<{}, ContactFormState>{
    constructor(props: any) {
        super(props);

        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            message: "",
            rodo: false,

            errorMessage: "",
            sendStatus: false,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRodoChange = this.handleRodoChange.bind(this);

        this.checkError = this.checkError.bind(this);
        this.sendMail = this.sendMail.bind(this);
    }

    handleInputChange(event: any) {
		this.setState({
			[event.target.name]: event.target.value
		} as Pick<ContactFormState, keyof ContactFormState>);
    }

    handleRodoChange() {
        this.setState({
            rodo: !this.state.rodo
        })
    }

    checkError() {
        if(this.state.firstname.trim() === "") {
            return "Imię jest puste";
        }

        if(this.state.lastname.trim() === "") {
            return "Nazwisko jest puste";
        }

        if(this.state.email.trim() === "") {
            return "Email jest pusty";
        }

        if(this.state.phone.trim() === "") {
            return "Numer telefonu jest pusty";
        }

        if(this.state.message.trim() === "") {
            return "Wiadomość jest pusta";
        }

        if(this.state.rodo === false) {
            return "Niezaakceptowana zgoda na przetwarzanie danych osobowych";
        }

        return "";
    }

    sendMail(event: any) {
        event.preventDefault();

        let error = this.checkError();

        if(error !== "") {
            this.setState({
                errorMessage: error
            });

            return;
        } else {
            this.setState({
                errorMessage: ""
            });
        }

        let xhr = new XMLHttpRequest();
        xhr.open("POST", "/sendFormMessage.php", true);

        let data = new FormData();
        data.append("firstname", this.state.firstname);
        data.append("lastname", this.state.lastname);
        data.append("email", this.state.email);
        data.append("phone", this.state.phone);
        data.append("message", this.state.message);
        data.append("rodo", "" + this.state.rodo);

        xhr.onreadystatechange = (ev) => {
            if(xhr.readyState == 4) {
                if(xhr.status == 200) {
                    let response;

                    try{
                        response = JSON.parse(xhr.response);
                    } catch(e) {
                        response = {} as any;
                        response.status = "failed";
                        console.log("json parse error");
                    }

                    if(response.status === "success") {
                        this.setState({
                            sendStatus: true,
                            errorMessage: "",
                        });
                    } else {
                        this.setState({
                            sendStatus: false,
                            errorMessage: "Wiadomość nie została wysłana, błąd po stronie serwera",
                        });
                    }

                } else {
                    this.setState({
                        sendStatus: false,
                        errorMessage: "Wiadomość nie została wysłana, błąd po stronie serwera",
                    });
                }
            }
        }

        xhr.send(data);
    }

    render() {
        return (
            <form className="contact-form">
                {this.state.errorMessage === "" ? "" : <div className="contact-form__error">{this.state.errorMessage}</div>}
                {this.state.sendStatus === true ? <div className="contact-form__success">Wiadomość została wysłana</div> : ""}

                <ContactFormInput onChange={this.handleInputChange} value={this.state.firstname} type="text" name="firstname" placeholder="Imię"/>
                <ContactFormInput onChange={this.handleInputChange} value={this.state.lastname} type="text" name="lastname" placeholder="Nazwisko"/>
                <ContactFormInput onChange={this.handleInputChange} value={this.state.email} type="email" name="email" placeholder="E-mail"/>
                <ContactFormInput onChange={this.handleInputChange} value={this.state.phone} type="phone" name="phone" placeholder="Telefon"/>
                <ContactFormTextarea onChange={this.handleInputChange} value={this.state.message} name="message" placeholder="Wiadomość"/>
                <ContactFormCheckbox
                    name="statute"
                    text="Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem poinformowany że przysługuje mi prawo dostępu do swoich dancyh, możliwości ich poprawiania, żadania zaprzestania ich przetwarzania. Administratorem danych osobowych jest nazwa firmy z pełnym adresem"
                    value={this.state.rodo}
                    onChange={this.handleRodoChange}
                />

                <Button onClick={this.sendMail} style="thin" textColor="black" backgroundColor="yellow">Wyślij</Button>
            </form>
        );
    }
}

function ContactFormInput(props: any) {
    return(
        <input 
            onChange={props.onChange}
            type={props.type} 
            name={props.name} 
            placeholder={props.placeholder} 
            value={props.value} 
            className="contact-form__input" 
        />
    );
}

function ContactFormTextarea(props: any) {
    return(
        <textarea 
            onChange={props.onChange}
            rows={1}
            name={props.name} 
            placeholder={props.placeholder} 
            value={props.value} 
            className="contact-form__input contact-form__textarea" 
        />
    );
}

function ContactFormCheckbox(props: any) {
    return(
        <label className="contact-form__checkbox-container">
            <input onChange={props.onChange} checked={props.value} type="checkbox" name={props.name} className="contact-form__checkbox" />

            <div className="contact-form__checkbox-text">
                {props.text}
            </div>
        </label>
    );
}

export default Contact;

import React from "react";
import BigCard from "./Card";

const PUBLIC_URL = process.env.PUBLIC_URL;

function Cooperation(props: any) {
    return (
        <div id="wspolpraca" className="coop">
            <BigCard reverse={true} image={`${PUBLIC_URL}/graphics/coop.png`}>
                <div className="big-card__title">Współpraca</div>
                <div className="big-card__text">
                    Jako agenci zdobyliśmy doświadczenie w pracy na rzecz
                    wielkich multiagencji. W oparciu o osobiste doświadczenia
                    oraz otoczenie biznesowe w jakim się znajdujemy udało nam
                    się stworzyć model współpracy pozwalający nie niemalże
                    nieograniczony rozwój zarówno osobisty jak i finansowy.{" "}
                    <br />
                    <br />
                    Nie pozostawiamy naszych agentów bez wsparcia, chętnie
                    dzielimy się wiedzą a pakiet wszystkich usług dostępnych w
                    całej grupie kapitałowej pozwala na zaoferowanie Klientom
                    bardzo wszechstronnej obsługi. W związku z tym, że
                    specjalizujemy się w obsłudze Klienta zamożnego możemy
                    nauczyć Cię jak takiego Klienta pozyskać i obsługiwać, a
                    przede wszystkim jak spowodować, żeby Twój telefon ciągle
                    dzwonił. Nasze doświadczenie w branży ubezpieczeń pokazuje,
                    że takie wsparcie jest najważniejsze w rozwoju każdego
                    agenta.
                    <br />
                    <br />
                    Oferujemy przejrzysty system prowizyjny oraz wypłaty z
                    tytułu kontraktów z TU.
                </div>
            </BigCard>

            <div className="coop__text"></div>
        </div>
    );
}

export default Cooperation;

import React from "react";

function Footer(props: any) {
    return (
        <footer>
            <div className="footer__left">
                <div className="footer__left-grid">
                    <div>
                        <b>KRS:</b> 0000744918
                    </div>
                    <div>
                        <b>NIP:</b> 9542795584
                    </div>
                    <div>
                        <b>REGON:</b> 381010428
                    </div>
                </div>
                <div className="footer__left-bottom">
                    <div>
                        <b>Adres:</b> Gabrieli Zapolskiej 7, 40-580 Katowice
                    </div>
                    <div>
                        <b>Kapitał zakładowy:</b> 50 000 opłacony w całości
                    </div>
                </div>
            </div>
            <span>&copy; Twoje Polisy 2023</span>
        </footer>
    );
}

export default Footer;

import React from "react";

function BigCard(props: any) {
    return(
        <div className={`big-card ${ props.reverse == true ? "big-card--reverse" : ""}`}>
            <div className="big-card__text-container">
                {props.children}
            </div>

            <img src={props.image} alt="" className="big-card__image" />
        </div>
    );
}

export default BigCard;
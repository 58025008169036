import React from "react";

import Nav from "./Nav";
import Main from "./Main";
import Offer from "./Offer";
import About from "./About";
import Contact from "./Contact";
import Cooperation from "./Cooperation";
import Footer from "./Footer";

import CarInsuranceForm from "./Forms";
import Group from "./Group";

interface AppState{
	carFormShown: boolean;
}

class App extends React.Component<{}, AppState> {
	constructor(props: any) {
		super(props);

		this.state = {
			carFormShown: false,	
		};

		this.showCarForm = this.showCarForm.bind(this);
		this.hideCarForm = this.hideCarForm.bind(this);
		this.toggleCarForm = this.toggleCarForm.bind(this);
	}

	showCarForm() {
		this.setState({
			carFormShown: true
		});

		document.documentElement.style.height = "var(--height)";
		document.documentElement.style.overflow = "hidden";
		document.documentElement.style.position = "relative";

		document.body.style.height = "var(--height)";
		document.body.style.overflow = "hidden";
		document.body.style.position = "relative";
	}

	hideCarForm() {
		this.setState({
			carFormShown: false,
		});

		document.documentElement.style.height = "unset";
		document.documentElement.style.overflow = "unset";
		document.documentElement.style.position = "unset";

		document.body.style.height = "unset";
		document.body.style.overflow = "unset";
		document.body.style.position = "unset";	
	}

	toggleCarForm() {
		if(this.state.carFormShown) {
			this.hideCarForm();
		} else {
			this.showCarForm();
		}
	}

	render() {
		return (
			<div id="app">
				<Nav />
				<Main />
				<Offer showCarForm={this.showCarForm} />
				<About />
				<Contact />
				<Cooperation />
				<Group/>
				<Footer />

				<CarInsuranceForm hideForm={this.hideCarForm} shown={this.state.carFormShown} />
			</div>
		);
    }
}

window.addEventListener("load", () => {
	document.documentElement.style.setProperty("--height", `${window.innerHeight}px`);
});

window.addEventListener("resize", () => {
	document.documentElement.style.setProperty("--height", `${window.innerHeight}px`);
});

export default App;

export default function Group() {
    return (
        <div id="prosperity" className="group">
            <div className="big-card__title">Grupa Prosperity</div>
            <div className="big-card__text">
                <h3>
                    Poza działem ubezpieczeń w ramach spółek należących do grupy
                    oferujemy również:
                </h3>
                <ul>
                    <li>
                        {" "}
                        kredyty (gotówkowe, hipoteczne, firmowe, konsolidacje)
                    </li>
                    <li>
                        {" "}
                        leasingi (operacyjny, finansowy, zwrotny, pożyczki
                        leasingowe)
                    </li>
                    <li> usługi księgowe (księgi handlowe, KPiR, ryczałt)</li>
                    <li>
                        {" "}
                        usługi prawne (spory, opiniowanie umów, obsługa spółek,
                        krs itd)
                    </li>
                    <li> oddłużanie (upadłości, restrukturyzacje)</li>
                </ul>
            </div>

            <a target="_blank" href="https://czasprosperity.pl" className="button button-background-color--yellow button-text-color--black">
                Odwiedź strone
            </a>
        </div>
    );
}

import React from "react";

function Button(props: any) {
    return(
        <button onClick={props.onClick} className={`
            button 
            ${props.style != undefined ? "button-style--" + props.style : ""}
            ${props.textColor != undefined ? "button-text-color--" + props.textColor : ""}
            ${props.backgroundColor != undefined ? "button-background-color--" + props.backgroundColor : ""}

        `}>
            {props.children}
        </button>
    );
}

export default Button;

import { stringify } from "querystring";
import React, { PropsWithoutRef } from "react";

import Button from "./Button";

interface NavState{
    navHidden: boolean;
}

const PUBLIC_URL = process.env.PUBLIC_URL;

class Nav extends React.Component<any, NavState>{
    constructor(props: any) {
        super(props);

        this.state = {
            navHidden: true,
        }


        this.callButtonHandler = this.callButtonHandler.bind(this);
        this.burgerClickHandler = this.burgerClickHandler.bind(this);
        this.linkClickHandler = this.linkClickHandler.bind(this);

        this.showMobileNav = this.showMobileNav.bind(this);
        this.hideMobileNav = this.hideMobileNav.bind(this);
        this.toggleMobileNav = this.toggleMobileNav.bind(this);
    }

    callButtonHandler() {
        this.setState({
            navHidden: true
        });

        var loc: any = "tel:512995816";
        window.location = loc;
    }

    burgerClickHandler() {
        this.toggleMobileNav();
    }

    linkClickHandler() {
        this.hideMobileNav();
    }

    toggleMobileNav() {
        this.state.navHidden ? this.showMobileNav() : this.hideMobileNav();
    }

    showMobileNav() {
        this.setState({
            navHidden: false,
        });

		document.documentElement.style.height = "var(--height)";
		document.documentElement.style.overflow = "hidden";
		document.documentElement.style.position = "relative";

		document.body.style.height = "var(--height)";
		document.body.style.overflow = "hidden";
		document.body.style.position = "relative";
    }

    hideMobileNav() {
        this.setState({
            navHidden: true,
        });

		document.documentElement.style.height = "unset";
		document.documentElement.style.overflow = "unset";
		document.documentElement.style.position = "unset";

		document.body.style.height = "unset";
		document.body.style.overflow = "unset";
		document.body.style.position = "unset";	
    }

    render() {
        return(
            <nav>
                <div className="nav-container">
                    <NavLogo src={`${PUBLIC_URL}/graphics/logo.png`}/>

                    <div className={` nav-links ` + (this.state.navHidden ? "" : "nav-links--shown")}>
                        <NavLink onClick={this.linkClickHandler} href="#oferta">Oferta</NavLink>
                        <NavLink onClick={this.linkClickHandler} href="#onas">O nas</NavLink>
                        <NavLink onClick={this.linkClickHandler} href="#kontakt">Kontakt</NavLink>
                        <NavLink onClick={this.linkClickHandler} href="#wspolpraca">Współpraca</NavLink>
                        <NavLink onClick={this.linkClickHandler} href="#prosperity">Grupa Prosperity</NavLink>
                    </div>

                    <div 
                        onClick={this.burgerClickHandler} 
                        className={" nav-burger " + (this.state.navHidden ? "" : "nav-burger--active")}
                    >
                        <div className="nav-burger__line"></div>
                        <div className="nav-burger__line"></div>
                        <div className="nav-burger__line"></div>
                    </div>
                </div>
            </nav>
        );
    }
}

function NavLogo(props: React.ComponentPropsWithoutRef<"img">) {
    return (
        <img src={props.src} className="nav-logo" alt="LOGO" />
    );
}

function NavLink(props: React.ComponentPropsWithoutRef<"a">) {
    return(
        <a onClick={props.onClick} className="nav-link" href={props.href}>{props.children}</a>
    );
}

export default Nav;

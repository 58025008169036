import React from "react";
import BigCard from "./Card";

const PUBLIC_URL = process.env.PUBLIC_URL;

function About(props: any) {
    return (
        <div id="onas" className="about" style={{flexDirection: "column", maxWidth: "100ch", padding: "3rem", margin: "auto"}}>
            {/*<BigCard image={`${PUBLIC_URL}/graphics/about.png?v=1.0`}>*/}
                <div className="big-card__title">O nas</div>
                <div className="big-card__text">
                    Jesteśmy jedną ze spółek powiązanych z Prosperity S.A.
                    powstałą w celu kompleksowej obsługi naszych Klientów i
                    akcjonariuszy w szerokim zakresie ubezpieczeń. Wieloletnie
                    doświadczenie branżowe oraz szeroki wachlarz usług
                    dodatkowych pozwalają na zapewnienie obsługi na najwyższym
                    poziomie zarówno na etapie zawierania polisy jak i jej
                    „serwisu”. Jesteśmy zespołem najlepszych specjalistów
                    pracujących w oparciu o długofalową relacje z Klientem.
                </div>
            {/*</BigCard>*/}
        </div>
    );
}

export default About;

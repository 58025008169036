import React from "react";

const PUBLIC_URL = process.env.PUBLIC_URL;

function Main(props: any) {
    return (
        <div>
            <main>
                <div className="main-grid">
                    <div className="main-grid__left">
                        <a href="#oferta" className="main-cta">
                            Zobacz naszą ofertę
                            <div className="main-cta__arrow-container">
                                <svg
                                    className="main-cta__arrow"
                                    fill="white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                                </svg>
                            </div>
                        </a>
                    </div>

                    <div className="main-grid__right">
                        <img
                            src={`${PUBLIC_URL}/graphics/main.png?v=1.0`}
                            alt=""
                            className="main-image"
                        />
                    </div>
                </div>

                <div className="main-text">
                    Tanie oc na 20 letniego passata znajdziesz w internecie, ale
                    jeśli szukasz pakietu oc/ac na auto klasy wyższej lub
                    premium to lepiej trafić się nie da ;)
                </div>
            </main>

            <div className="main-mobile">
                <div className="main-text">
                    Tanie oc na 20 letniego passata znajdziesz w internecie, ale
                    jeśli szukasz pakietu oc/ac na auto klasy wyższej lub
                    premium to lepiej trafić się nie da ;)
                </div>

                <img
                    src={`${PUBLIC_URL}/graphics/main.png?v=1.0`}
                    alt=""
                    className="main-image"
                />

                <a href="#oferta" className="main-cta">
                    Zobacz naszą ofertę
                    <div className="main-cta__arrow-container">
                        <svg
                            className="main-cta__arrow"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Main;
